<template>
  <super-admin-page-card
    pageName="Upload User Guide"
    iconClass="cuis-cloud-upload card-icon-font"
  >
    <div>
      <div class="logo-upload">
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-4">
              <div class="col-sm-4 align-self-center">
                Select UI Module:
              </div>
              <div class="col-sm-8 pl-0">
                <multiselect
                  v-model="selectedModule"
                  label="displayName"
                  track-by="id"
                  placeholder="Type module name to search"
                  :options="modules"
                  :loading="isLoading"
                  :multiple="false"
                  :searchable="false"
                  class="institution-select"
                >
                  <span slot="noResult">
                    No matching modules found...
                  </span>
                </multiselect>
              </div>
            </div>
            <div
              class="row mb-4"
              v-if="selectedModule && selectedModule.file != ''"
            >
              <div class="col-sm-6 ">
                Download current user guide
                <a :href="selectedModule.file" target="_blank">here</a>.
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-sm-4 align-self-center">
                Choose File:
              </div>
              <div class="col-sm-8 pl-0">
                <input
                  type="file"
                  name="user_guide"
                  id="user_guide"
                  class="inputfile"
                  ref="user_guide"
                  accept="application/pdf"
                  @change="handleFileUpload()"
                />
                <label
                  for="user_guide"
                  :class="[
                    'btn btn-primary  rounded mb-0 file-label',
                    selectedModule ? 'enable-upload' : 'disable-upload'
                  ]"
                >
                  <i class="cuis-cloud-upload pr-1"></i>
                  Choose a user guide
                </label>
                <span class="pl-1 font-weight-bold" v-if="user_guide">
                  [{{ user_guide.name }}]
                </span>
              </div>
            </div>

            <div class="row mb-4 pt-2" v-if="selectedModule">
              <div class="col-sm-12 text-center mt-5">
                <b-button
                  size=""
                  variant="primary"
                  @click="submitFile()"
                  class="fw-btn"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </super-admin-page-card>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'UploadUserGuides',
  components: {
    Multiselect
  },
  data() {
    return {
      modules: [],
      selectedModule: null,
      isLoading: false,
      user_guide: null
    }
  },
  created() {
    this.loadUiModules()
  },
  computed: {
    validFormats() {
      return ['application/pdf']
    }
  },
  methods: {
    loadUiModules() {
      this.isLoading = true
      this.$http
        .get('/api/super_admin/ui_modules', {
          handleErrors: true
        })
        .then(res => {
          this.modules = res.data.modules
          this.modules.forEach(m => {
            m.displayName = m.parent_module_name + ' -> ' + m.name
          })
        })
        .then(() => {
          this.isLoading = false
        })
    },
    submitFile() {
      let formData = new FormData()

      formData.append('module[id]', this.selectedModule.id)
      formData.append('module[user_guide]', this.user_guide)

      this.$http
        .post('/api/super_admin/ui_modules/upload_guide', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          handleErrors: true
        })
        .then(() => {
          this.resetData()
          this.loadUiModules()
          this.$toasted.show('User Guide successfully uploaded...', {
            icon: 'user-circle',
            type: 'success'
          })
        })
    },
    resetData() {
      this.user_guide = null
      this.selectedModule = null
    },
    handleFileUpload() {
      let chosenFile = this.$refs.user_guide.files[0]

      if (this.validFormats.includes(chosenFile.type)) {
        this.user_guide = chosenFile
        const reader = new FileReader()
        reader.readAsDataURL(this.user_guide)
      } else {
        this.$toasted.show('Invalid file format, please choose PDF files...', {
          icon: 'chain-broken',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-upload {
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-label {
    font-weight: bold;
    color: white;
  }
  .disable-upload {
    opacity: 0.65;
    cursor: not-allowed !important;
    pointer-events: none;
  }
}
</style>
